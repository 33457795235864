<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <div class="large--title m-0 d-flex align-center">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="mr-4"> Без имени </span>
            <div class="coden" style="width: 400px">
              <select-candidate
                :filter="{ added_as_staff: false }"
                v-model="form.candidate_id"
              ></select-candidate>
            </div>
          </div>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-5" v-loading="loadingData">
        <el-form ref="form" :model="form" :rules="rules">
          <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
            <el-row :gutter="20" class="mb-5">
              <el-col :span="6" class="pt-3">
                <div id="app" class="my-upload">
                  <el-upload
                    class="upload-demo"
                    action="/"
                    :limit="1"
                    :on-change="createImageList"
                    :file-list="imageList"
                    list-type="picture-card"
                    :auto-upload="false"
                    :class="mode ? '' : 'upload__mynight'"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <!-- <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="" />
                  </el-dialog> -->
                  <!-- <div v-if="!image" class="u-file">
                    <label
                      for="file-upload"
                      class="my-button ml-2 colorQr width-auto"
                    >
                      <i class="el-icon-plus"></i>
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      @change="onFileChange"
                    />
                  </div>
                  <div v-else>
                    <img
                      :src="form.image ? baseUrl + form.image.path : form.image"
                      class="upload-img-task"
                    />
                    <br />
                    <el-button
                      size="mini"
                      @click="removeImage"
                      type="danger"
                      plain
                      class="dalet-img"
                    >
                      Удалить
                    </el-button>
                  </div> -->
                </div>
              </el-col>
              <el-col :span="18">
                <el-row :gutter="20" class="mt-5">
                  <!-- <el-col :span="24">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        ID сотрудника
                      </span>
                      <el-input
                        placeholder="ID сотрудника"
                        v-model="form.value1"
                        size="medium"
                      ></el-input>
                    </div>
                  </el-col> -->

                  <el-col :span="8">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.last_name") }}
                      </span>
                      <el-form-item prop="last_name">
                        <crm-input 
                          :class="mode ? 'input__day' : 'input__night'"
                          :placeholder="$t('message.last_name')"
                          :inputValue="form.last_name"
                          v-model="form.last_name"
                          :size="'medium'"
                        ></crm-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.middle_name") }}
                      </span>
                      <el-form-item prop="middle_name">
                        <crm-input 
                          :class="mode ? 'input__day' : 'input__night'"
                          :placeholder="$t('message.middle_name')"
                          :inputValue="form.middle_name"
                          v-model="form.middle_name"
                          :size="'medium'"
                        ></crm-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.nameFull") }}
                      </span>
                      <el-form-item prop="name">
                        <crm-input 
                          :class="mode ? 'input__day' : 'input__night'"
                          :placeholder="$t('message.nameFull')"
                          :inputValue="form.name"
                          v-model="form.name"
                          :size="'medium'"
                        ></crm-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.date_of_birth") }}
                      </span>
                      <el-form-item prop="date_of_birth">
                        <crm-date-picker
                          :class="mode ? 'input__day' : 'input__night'"
                          :size="'medium'"
                          :placeholder="$t('message.date_of_birth')"
                          :date="form.date_of_birth"
                          v-model="form.date_of_birth"
                        ></crm-date-picker>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="5">
                      <div class="app-form__group mb-4">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                         {{$t("message.country_passport")}}
                        </span>
                         <el-select
                              class="w-100"
                               v-model="form.maskFormat"
                              placeholder="Процесс онбординга "
                              size="medium"
                              @change="changeMask"
                              :class="mode ? 'input__day' : 'input__night'"
                            >
                              <el-option label="Узбекистан" value="pas_uz"></el-option>
                              <el-option label="Турция" value="pas_tr"></el-option>
                              <el-option label="Индия" value="pas_ind"></el-option>
                              <el-option label="Россия" value="pas_ru"></el-option>
                              <el-option label="Казахстан" value="pas_kz"></el-option>
                              <el-option label="Киргизстан" value="pas_kg"></el-option>
                            </el-select>
                        </div>
                  </el-col>
                  <el-col :span="5">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.passport_number") }}
                      </span>
                           <!-- <el-input
                              v-model="form.passport_number"
                              @input="dispatch"
                              :type="'text'"
                              placeholder="AA1111010"
                              :size="'medium'"
                              :class="'w-100'"
                              clearable
                              :key="maskFormat"
                              v-mask="getMaskFormat"
                            >
                            </el-input> -->
                            <el-form-item prop="passport_number">
                              <crm-input 
                                :class="mode ? 'input__day' : 'input__night'" 
                                :placeholder="$t('message.passport_number') "
                                :maskFormat="form.maskFormat"
                                :inputValue="form.passport_number"
                                v-model="form.passport_number"
                                :size="'medium'"
                              ></crm-input>
                              </el-form-item>
                        
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.passport_issued_by") }}
                      </span>
                      <el-form-item prop="passport_issued_by">
                        <crm-input 
                          :class="mode ? 'input__day' : 'input__night'"
                          :placeholder="$t('message.passport_issued_by')"
                          :inputValue="form.passport_issued_by"
                          v-model="form.passport_issued_by"
                          :size="'medium'"
                        ></crm-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.passport_valid_until") }}
                      </span>
                      <el-form-item prop="passport_valid_until">
                        <crm-date-picker
                          :class="mode ? 'input__day' : 'input__night'"
                          v-model="form.passport_valid_until"
                          :date="form.passport_valid_until"
                          :size="'medium'"
                        >
                        </crm-date-picker>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.email") }}
                      </span>
                      <el-form-item prop="email">
                        <crm-input 
                          :class="mode ? 'input__day' : 'input__night'"
                          :type="'email'"
                          :placeholder="$t('message.email')"
                          :inputValue="form.email"
                          v-model="form.email"
                          :size="'medium'"
                        ></crm-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.phone_number") }}
                      </span>
                      <el-form-item prop="phone_number">
                        <crm-input 
                          :class="mode ? 'input__day' : 'input__night'"
                          :type="'tel'"
                          :maskFormat="'tel'"
                          :placeholder="$t('message.phone_number')"
                          :inputValue="form.phone_number"
                          v-model="form.phone_number"
                          :size="'medium'"
                        ></crm-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.income") }}
                      </span>
                      <el-form-item prop="income_id">
                        <select-income
                          v-model="form.income_id"
                          :size="'medium'"
                        ></select-income>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                        $t("message.gender")
                      }}</span>
                      <el-form-item prop="gender_id">
                        <select-gender
                          :id="form.gender_id"
                          v-model="form.gender_id"
                          :size="'medium'"
                        ></select-gender>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                        $t("message.family_location")
                      }}</span>
                      <el-form-item>
                        <select-family-status
                          :id="form.family_status_id"
                          v-model="form.family_status_id"
                          :size="'medium'"
                        ></select-family-status>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                        {{ $t("message.pinfl") }}
                      </span>
                      <el-form-item prop="pinfl">
                        <crm-input 
                          :class="mode ? 'input__day' : 'input__night'"
                          :placeholder="'9 999999 999 999 9'"
                          v-model="form.inn"
                          :maskFormat="'pinfl'"
                          :inputValue="form.inn"
                          :size="'medium'"
                        ></crm-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                        $t("message.status")
                      }}</span>
                      <el-form-item prop="status_id">
                        <select-status-staff
                          :id="form.status_id"
                          v-model="form.status_id"
                          :table_name="'staffs'"
                          :size="'medium'"
                        ></select-status-staff>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                        $t("message.work_type")
                      }}</span>
                      <el-form-item prop="work_type_id">
                        <select-work-type
                          :id="form.work_type_id"
                          v-model="form.work_type_id"
                          :size="'medium'"
                        >
                        </select-work-type>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                        $t("message.nation")
                      }}</span>
                      <el-form-item prop="nation_id">
                        <select-nation
                          :size="'medium'"
                          :placeholder="columns.nation_id.title"
                          :id="form.nation_id"
                          v-model="form.nation_id"
                        >
                        </select-nation>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                        $t("message.staff_work_type")
                      }}</span>
                      <el-form-item prop="staff_work_type_id">
                        <select-staff-work-type
                          :size="'medium'"
                          :placeholder="columns.staff_work_type_id"
                          :id="form.staff_work_type_id"
                          v-model="form.staff_work_type_id"
                        >
                        </select-staff-work-type>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="7">
                      <div class="app-form__group mb-4">
                        <div class="app-form__group mb-4">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                            $t("message.region")
                          }}</span>
                          <el-form-item prop="region_id">
                            <select-region
                              :id="form.region_id"
                              v-model="form.region_id"
                              :size="'medium'"
                            >
                            </select-region>
                          </el-form-item>
                        </div>
                      </div>
                  </el-col>
                <el-col :span="7">
                  <div class="app-form__group mb-4">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                        $t("message.district_id")
                      }}</span>
                      <el-form-item prop="district_id">
                        <select-district
                          :region_id="form.region_id"
                          v-model="form.district_id"
                          :id="form.district_id"
                          :size="'medium'"
                        ></select-district>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                 <el-col :span="10">
                  <div class="app-form__group mb-4">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                        $t("message.address")
                      }}</span>
                       <el-form-item prop="address">
                        <crm-input 
                          :class="mode ? 'input__day' : 'input__night'"
                          :placeholder="$t('message.address')"
                          v-model="form.address"
                          :inputValue="form.address"
                          :size="'medium'"
                        ></crm-input>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
                </el-row>
              </el-col>
            </el-row>
            
            <el-row :gutter="20" class="mt-5">
              <el-col :span="24">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.job") }}</span
                  >
                  <hr />
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="mt-5">
              <el-col :span="6">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.hiring_date") }}
                  </span>
                  <el-form-item prop="hiring_date">
                    <crm-date-picker
                      :class="mode ? 'input__day' : 'input__night'"
                      :date="hiring_date"
                      v-model="form.hiring_date"
                      :size="'medium'"
                      :format="'DD.MM.YYYY'"
                    >
                    </crm-date-picker>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="mt-5">
              <el-col :span="6">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                    $t("message.employmentType")
                  }}</span>
                  <el-form-item prop="employment_type_id">
                    <select-employment-type
                      :id="form.employment_type_id"
                      v-model="form.employment_type_id"
                      :size="'medium'"
                    ></select-employment-type>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.graphic") }}</span
                  >
                  <el-form-item prop="graphic_id">
                    <select-graphic
                      :id="form.graphic_id"
                      v-model="form.graphic_id"
                      :size="'medium'"
                    ></select-graphic>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="6" v-if="is_free">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{$t("message.limit_work_time")}}</span
                  >
                  <el-form-item prop="monthly_work_hour">
                    <!-- <select-graphic
                      :id="form.monthly_work_hour"
                      v-model="form.monthly_work_hour"
                      :size="'medium'"
                    ></select-graphic> -->
                    <crm-input 
                        :class="mode ? 'input__day' : 'input__night'"
                        :placeholder="$t('message.limit_work_time')"
                        v-model="form.monthly_work_hour"
                        :inputValue="form.monthly_work_hour"
                        :type="'number'"
                        :size="'medium'"
                        ></crm-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="20" class="mt-5">
              <el-col :span="6">
                <div class="app-form__group mb-4">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                      {{ $t("message.company") }}</span
                    >
                    <el-form-item prop="company_id">
                      <select-company
                        :id="form.company_id"
                        v-model="form.company_id"
                        :size="'medium'"
                      ></select-company>
                    </el-form-item>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="app-form__group mb-4">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                      {{ $t("message.branch") }}</span
                    >
                    <el-form-item prop="branch_id">
                      <select-branch
                        :company_id="form.company_id"
                        v-model="form.branch_id"
                        :id="form.branch_id"
                        :size="'medium'"
                      ></select-branch>
                    </el-form-item>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="app-form__group mb-4">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                      $t("message.department")
                    }}</span>
                    <el-form-item prop="department_id">
                      <select-department
                        :branch_id="form.branch_id"
                        v-model="form.department_id"
                        :id="form.department_id"
                        :size="'medium'"
                      ></select-department>
                    </el-form-item>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="app-form__group mb-4">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                      $t("message.position")
                    }}</span>
                    <el-form-item prop="position_id">
                      <select-position
                        :branch_id="form.branch_id"
                        v-model="form.position_id"
                        :size="'medium'"
                      ></select-position>
                    </el-form-item>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="app-form__group mb-4">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                      $t("message.position_2")
                    }}</span>
                    <el-form-item >
                      <select-position
                        :company_id="form.company_id"
                        :id="form.position_ids"
                        v-model="form.position_ids"
                        :size="'medium'"
                        :multiple="true"
                      ></select-position>
                    </el-form-item>
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                  <div class="app-form__group mb-4">
                    <div class="app-form__group mb-4">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                        $t("message.category")
                      }}</span>
                      <el-form-item prop="category_id">
                        <select-category
                          :company_id="form.company_id"
                          :id="form.category_id"
                          v-model="form.category_id"
                          :size="'medium'"
                        ></select-category>
                      </el-form-item>
                    </div>
                  </div>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="mt-5">
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                    $t("message.manager")
                  }}</span>
                  <el-form-item prop="manager_id">
                    <select-staff
                      :placeholder="$t('message.manager')"
                      :id="form.manager_id"
                      v-model="form.manager_id"
                      :size="'medium'"
                    >
                    </select-staff>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>

            <!-- <el-row :gutter="20" class="mt-5">
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    Процесс онбординга
                  </span>
                  <el-form-item prop="">
                  <el-select
                    class="w-100"
                    v-model="form.valu2"
                    placeholder=" Процесс онбординга "
                    size="medium"
                  >
                    <el-option label="Zone one" value="shangh"></el-option>
                    <el-option label="Zone two" value="beijing"></el-option>
                  </el-select>
                  </el-form-item>
                </div>
              </el-col>             
            </el-row> -->

            <!-- <el-row :gutter="20" class="mt-5">
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.allow_th_employee_to_access_the_system") }}
                  </span>
                  <el-switch v-model="form.allow_to_be_user"> </el-switch>
                </div>
              </el-col>
            </el-row> -->
            <el-row :gutter="20" class="mt-5">
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.leader") }}
                  </span>
                  <el-switch v-model="form.is_leader"> </el-switch>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="20" class="mt-5">
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.allow_salary") }}
                  </span>
                  <el-switch v-model="form.allow_salary"> </el-switch>
                </div>
              </el-col>
            </el-row>
            <div v-if="form.allow_salary">
              <el-row :gutter="20" class="mt-5">
                <el-col :span="24">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">  Зарплата</span
                    >
                    <hr />
                  </div>
                </el-col>
              </el-row>

              <el-row :gutter="20" class="mt-5">
                <el-col :span="24">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">  Пластиковая карта</span
                    >
                   
                  </div>
                </el-col>
              </el-row>

              <el-row :gutter="20">
                <el-col :span="12">
                  <div class="app-form__group mb-8">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">Номер карты</span>
                    <el-form-item prop="card_number">
                      <crm-input 
                        :class="mode ? 'input__day' : 'input__night'"
                        :maskFormat="'card'"
                        :placeholder="'Номер карты'"
                        :inputValue="formCard.card_number"
                        v-model="formCard.card_number"
                        :size="'medium'"
                      ></crm-input>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="app-form__group mb-8">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"
                      >Дата истечения срока</span
                    >
                    <el-form-item prop="expiry_date">
                      <crm-input 
                        :class="mode ? 'input__day' : 'input__night'"
                        :maskFormat="'card_expiry_date'"
                        :placeholder="'Дата истечения срока'"
                        :inputValue="formCard.expiry_date"
                        v-model="formCard.expiry_date"
                        :size="'medium'"
                      >
                      </crm-input>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="app-form__group mb-8">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.card_organ")}}</span>
                    <el-form-item prop="card_authority">
                      <crm-input 
                        :class="mode ? 'input__day' : 'input__night'"
                        :placeholder="$t('message.card_organ')"
                        :inputValue="formCard.card_authority"
                        v-model="formCard.card_authority"
                        :size="'medium'"
                      >
                      </crm-input>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="app-form__group mb-8">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t('message.status')}}</span>
                    <el-form-item prop="status_id">
                      <select-status-card
                        :id="formCard.status_id"
                        :table_name="'cards'"
                        v-model="formCard.status_id"
                        :size="'medium'"
                      ></select-status-card>
                    </el-form-item>
                  </div>
                </el-col>              
              </el-row>

              <el-row :gutter="20" class="mt-5">
                <el-col :span="24">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t('message.salary')}}</span
                    >
                   
                  </div>
                </el-col>
              </el-row>

              <el-row :gutter="20">
                <el-col :span="12">
                  <div class="app-form__group mb-8">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t('message.type_salary')}}</span>
                    <el-form-item prop="type">
                      <el-select
                        class="w-100"
                        v-model="formSalary.type"
                        :placeholder="$t('message.type_salary')"
                        size="medium"
                        filterable
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                        <el-option
                          v-for="(type, index) in types"
                          :key="'type-' + index"
                          :label="type.label"
                          :value="type.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </el-col>

                <el-col :span="12">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.currency")}}</span>
                    <el-form-item prop="currency_id">
                      <select-currency
                        v-model="formSalary.currency_id"
                        :id="formSalary.currency_id"
                        size="medium"
                      ></select-currency>
                    </el-form-item>
                  </div>
                </el-col>

                <el-col :span="12">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t('message.official_amount')}} </span>
                    <el-form-item prop="official_amount">
                      <crm-input 
                        :class="mode ? 'input__day' : 'input__night'"
                        :placeholder="$t('message.official_amount')"
                        v-model="formSalary.official_amount"
                        :inputValue="formSalary.official_amount"
                        :type="'number'"
                        size="medium"
                      ></crm-input>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                       {{$t('message.unofficial_amount')}}
                    </span>
                    <el-form-item prop="unofficial_amount">
                      <crm-input 
                        :class="mode ? 'input__day' : 'input__night'"
                        :placeholder="$t('message.unofficial_amount')"
                        v-model="formSalary.unofficial_amount"
                        :inputValue="formSalary.unofficial_amount"
                        :type="'number'"
                        size="medium"
                      ></crm-input>
                    </el-form-item>
                  </div>
                </el-col>

                <el-col :span="12" v-if="formSalary.type == 'hourly'">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t("message.amount_per_hour")}} </span>
                    <el-form-item prop="amount_per_hour">
                      <crm-input 
                        :class="mode ? 'input__day' : 'input__night'"
                        placeholder="	В час "
                        v-model="formSalary.amount_per_hour"
                        :inputValue="formSalary.amount_per_hour"
                        :type="'number'"
                        size="medium"
                      ></crm-input>
                    </el-form-item>
                  </div>
                </el-col>
                <!-- end col -->

                <el-col :span="12">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.status")}}</span>
                    <el-form-item prop="status_id">
                      <select-status-salaries
                        :table_name="'salaries'"
                        :id="formSalary.status_id"
                        v-model="formSalary.salary_status_id"
                        size="medium"
                      >
                      </select-status-salaries>
                    </el-form-item>
                  </div>
                </el-col>
                <!-- end col -->

                <el-col :span="12">
                  <div class="app-form__group mb-4">
                    <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t("message.datas")}}</span>
                    <el-form-item prop="date_of_start">
                      <crm-date-picker
                        :class="mode ? 'input__day' : 'input__night'"
                        :date="formSalary.date_of_start"
                        :placeholder="$t('message.datas')"
                        v-model="formSalary.date_of_start"
                        size="medium"
                      ></crm-date-picker>
                    </el-form-item>
                  </div>
                </el-col>
                <!-- end col -->
              </el-row>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import selectStatusCard from "@/components/filters/inventory/select-status-card";
import selectStatusSalaries from "@/components/filters/inventory/select-status-salaries";
import selectStatusStaff from "@/components/filters/inventory/select-status-staff";
import selectCandidate from "@/components/filters/inventory/select-candidate";
import selectStaffWorkType from "@/components/filters/inventory/select-staff-work-type";
import selectStatus from "@/components/filters/inventory/select-status";
import selectFamilyStatus from "@/components/filters/inventory/select-familyStatus";
import selectGraphic from "@/components/filters/inventory/select-graphic";
import selectGender from "@/components/filters/inventory/select-gender";
import selectRegion from "@/components/filters/inventory/select-region";
import selectDistrict from "@/components/filters/inventory/select-district";
import selectPosition from "@/components/filters/inventory/select-position";
import selectCompany from "@/components/filters/inventory/select-company";
import selectBranch from "@/components/filters/inventory/select-branch";
import selectDeparment from "@/components/filters/inventory/select-department";
import selectEmploymentType from "@/components/filters/inventory/select-employmentType";
import selectIncome from "@/components/filters/inventory/select-income";
import selectWorkType from "@/components/filters/inventory/select-work-type";
import selectStaff from "@/components/filters/inventory/select-staff";
import selectNation from "@/components/filters/inventory/select-nation";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import moment from 'moment';
export default {
  mixins: [drawer, form],
  name: "StaffController",
  data() {
    return {
      formSalary:{},
      formCard:{},
      is_free:false,
      image: "",
      hiring_date: moment(new Date()).format("DD.MM.YYYY"),
      form:{
      allow_to_be_user:false,
      maskFormat:'pas_uz',
      },
      loadingData: false,
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      ),
       types: [
        { label: "Фиксированная оплата", value: "fixed" },
        { label: "Почасовая оплата", value: "hourly" },
        //{ label: "PIECEWORK", value: "piecework" },
      ],
      imageList: [],
      candidate_id: null,
    };
  },
  props: {
    company_id: {
      default: null,
    },
    branch_id: {
      default: null,
    },
    department_id: {
      default: null,
    },
    company_id_from_deparment: {
      default: null,
    },
  },
  watch: {
    "form.candidate_id": {
      handler: function () {
        this.imageList = [];
        this.fetchCandidate();
      },
    },
    "form.company_id": {
      handler: function () {},
      deep: true,
    },
    "form.staff_work_type_id": {
      handler: function (newVal, oldVal) {
      },
    },
    "form.graphic_id": {
      handler: function (newVal, oldVal) {
        // this.is_free = _.filter(this.staffs, ["department_id", this.department_id])
        if (newVal) {
          this.is_free = this.graphics.find(x => x.id === newVal).is_free;
          console.log(this.is_free);
        }
        // console.log(this.graphics);
      },
      deep: true,
      immediate: true,
    },
    company_id: {
      handler: async function (newValue, oldValue) {},
      deep: true,
      immediate: true,
    },
    branch_id: {
      handler: function (newValue, oldValue) {},
      deep: true,
      immediate: true,
    },
    department_id: {
      deep: true,
      immediate: true,
    },
    // maskFormat () {
    //   console.log("Work")
    // } 
     maskFormat: {
      handler: function (e) {
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    selectCandidate,
    selectStatus,
    selectGender,
    selectRegion,
    selectDistrict,
    selectEmploymentType,
    selectFamilyStatus,
    selectPosition,
    selectCompany,
    selectBranch,
    selectDeparment,
    selectStaff,
    selectIncome,
    selectWorkType,
    selectNation,
    selectGraphic,
    selectFamilyStatus,
    selectStaffWorkType,
    selectStatusStaff,
    selectStatusCard,
    selectStatusSalaries

  },
  computed: {
    ...mapGetters({
      rules: "staff/rules",
      model: "staff/model",
      columns: "staff/columns",
      candidate: "candidate/model",
      graphics: 'graphic/inventory',
      mode: "MODE"
    }),
    
  },

  methods: {
    ...mapActions({
      showCanidate: "candidate/show",
      save: "staff/store",
      empty: "staff/empty",
    }),
    afterOpen() {
      this.form.hiring_date =moment(new Date()).format("DD.MM.YYYY");
      if (!this.company_id) {
        setTimeout(
          () => (this.form.company_id = this.company_id_from_deparment),
          500
        );
      } else {
        setTimeout(() => (this.form.company_id = this.company_id), 500);
      }
      setTimeout(() => (this.form.branch_id = this.branch_id), 500);
      setTimeout(() => (this.form.department_id = this.department_id), 500);
    },
    createImageList(file) {
      this.form.image = file.raw;
      this.form.allow_to_be_user = false;
    },
    fetchCandidate() {
      if (!this.loadingData && this.form.candidate_id) {
        this.loadingData = true;
        this.empty();
        this.showCanidate(this.form.candidate_id)
          .then((res) => {
            this.form = {
              ...this.form,
              ...JSON.parse(JSON.stringify(this.candidate)),
            };
            if (this.form.image) {
              this.imageList.push({
                name: this.form.image.path,
                url: this.baseUrl + this.form.image.path,
              });
            }
            delete this.form.status_id;
            this.loadingData = false;
            this.candidate_id = res.data.result.data.candidate.id;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
      if (!this.loadingData && !this.form.candidate_id) {
        this.empty();
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (e) {
      this.image = "";
    },
    submit(close = true) {
      this.form.allow_to_be_user = false;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.candidate_id != null)
            this.form.candidate_id = this.candidate_id;

          let formData = new FormData();
          
          for (const key in this.form) {
            formData.append(key, this.form[key]??'');
             if (key == "position_ids") {
              for (const id in this.form[key]) {
                formData.append("position_ids[]", this.form[key][id]);
              }
            }
          }
           for (const key in this.formCard) {
            formData.append("formCard["+key+"]", this.formCard[key]);
          }
          for (const key in this.formSalary) {
            formData.append("formSalary["+key+"]", this.formSalary[key]);
          }
          this.save(formData)
            .then((res) => {
              this.parent().listChanged();
              this.$alert(res);
              if (res.status == 201) {
                this.empty();
                this.fileList = [];
                this.imageList = [];
                this.close();
              }
            })
            .catch((err) => {
              this.$alert(err);
            });
        }
      });
    },
    afterLeave() {
      this.fileList = [];
      this.imageList = [];
      this.candidate_id = null;
      this.empty();
    },
    changeMask(val){
      this.form.passport_number = '';
      this.form.maskFormat = val;
    },
  },
};
</script>
<style lang="scss">
.el-drawer__body{
  overflow-y: scroll;
}
.my-upload {
  .upload-demo {
    position: relative;
  }
  .el-upload-list {
    position: relative;
    z-index: 2;
  }
  .el-upload.el-upload--picture-card {
    position: absolute;
    left: 0px !important;
    z-index: 1;
    width: 95%;
    height: 265px;
    line-height: 262px;
    vertical-align: top;
    // margin-left: 4%;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 95%;
    height: 265px;
    line-height: 262px;
    vertical-align: top;
    // margin-left: 4%;
  }
  .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.upload__mynight{
  .el-upload.el-upload--picture-card{
    background-color: transparent;
  }
}
</style>
